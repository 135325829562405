(function () {
    // Load check
    if (!jQuery) {
        console.log('NOT LOADED!');
        return;
    }
    
    (function ($) {
        window.OferaCRM = window.OferaCRM || {};
        OferaCRM.FE = OferaCRM.FE || {};
        OferaCRM.FE.Dropdown = {

            showLogs: true,

            /**
             * Transform option into list element.
             * 
             * @param   {DOM Object}  option  The option element from select.
             * @returns  {String}  The li element encompassing the 
             */
            _adaptMultiselectOption: function (option) {
                var $option = $(option);

                var html = '';

                if ($option.attr('selected')) {
                    html += '<li class="selected" data-value="' + $option.attr('value') + '" data-name="' + $option.html() + '">';
                    html += '<i class="far fa-check-square"></i> ';
                } else {
                    html += '<li data-value="' + $option.attr('value') + '" data-name="' + $option.html() + '">';
                    html += '<i class="far fa-square"></i> ';
                }

                html += $option.html();
                html += '</li>';

                return html;
            },

            /**
             * Parse the selection container to get the selected values into a digestible list.
             * 
             * @param  {DOM Object}  $container  The container to serach for selected values in.
             * @returns  {String}  The container html content.
             */
            _getMultiselectDisplayContent: function ($container) {
                var html = '';

                $container.find('li.selected').each(function () {
                    var $this = $(this);
                    html += '<li data-id="'+ $this.attr('data-value') + '">' + $this.attr('data-name') + ' <i class="fa fa-close"></i></li>';
                });

                return html;
            },

            /**
             * Compiles the string to be shown in the head of the dropdown.
             * 
             * @param  {DOM Object}  $container  The dom element holding the dropdown and options.
             * @returns {String} The string to be shown in the head of the dropdown.
             */
            _getMultiselectHeadString: function ($container) {
                var selectorCount = 0;
                var selectionString = '';
                var comma = '';

                $container.find('li.selected').each(function () {
                    var $this = $(this);

                    selectorCount++;
                    selectionString += comma + $this.attr('data-name');

                    comma = ', ';
                });

                if (selectionString) {
                    return selectorCount + ' (' + selectionString + ')';
                }

                return '';
            },

            /**
             * Treats clicking on a bubble to close
             * 
             * @param  {DOM Object}  $this       The element that was clicked (i.fa-close)
             * @param  {DOM Object}  $container  The container of the entire new dropdown.
             * @param  {DOM Object}  $selector   The "select" element.
             * 
             * @return {String}  The id of the removed element.
             */
            _clickOnBubbleClose: function ($this, $container, $selector) {
                var self = OferaCRM.FE.Dropdown;

                //OferaCRM.FE.Debug.log(self.showLogs, 'ImobCRM Dropdown - _clickOnBubbleClose', 'Elements', $this, $container, $selector);

                var $listElement = $this.closest('li');
                var id = $listElement.attr('data-id');
                $listElement.remove();

                var $containerLi = $container.find('li[data-value=' + id + ']');
                $containerLi.removeClass('selected');

                var $checkIcon = $containerLi.find('i.fa-check-square');
                $checkIcon.removeClass('fa-check-square');
                $checkIcon.addClass('fa-square');

                $selector.find('option[value=' + id +']').removeAttr('selected');
                return id;
            },

            _getFilterInputHtml: function ($this, length) {
                var optionCount = $this.find('option').length;

                if (optionCount > length) {
                    return '<div class="c-dropdown-advanced__body--filter option-filter"><input class="filter-input" type="text" /></div>';
                }

                return '';
            },

            /**
             * Gets the dropdown selector value.
             * 
             * @param  {DOM Object}  $selector    The select element used for parsing.
             * @param  {String}      placeholder  The string to be used if no selected value.
             * @returns  {String}  The html to be insterted in the head of the dropdown.
             */
            _getDropdownHead: function ($selector, placeholder) {
                var $selectedOption = $selector.find('option[selected]');
                var classString = 'selection-holder';
                var removeSelection = '<span class="remove-selection"><i class="fa fa-close"></i></span>';
                var dropdownIndicator = '<span class="dropdown-indicator"><span class="glyphicon glyphicon-triangle-bottom"></span></span>';
                if ($selectedOption.length > 0) {
                    classString += ' has-value';
                }
                if ($selector.attr('disabled')) {
                    classString = 'selection-holder disabled';
                    dropdownIndicator = '';
                    removeSelection = '';
                }
                var html = '';
                html += '<span class="' + classString + '">';
                html += $selectedOption.length > 0 ? $selectedOption.html() : placeholder;
                html += '</span>';
                html += removeSelection;
                html += dropdownIndicator;
                if ($selector.hasClass('with-addition')) {
                    html += '<span class="addition-indicator" title="' + $selector.attr('data-addition-title') + '" data-item="' + $selector.attr('data-item') + '"><i class="fa fa-plus"></i></span>';
                }

                return html;
            },

            bindDropdown : function () {
                var self = OferaCRM.FE.Dropdown;

                var $dropdown = $('select.dropdown');

                if (!$dropdown.length) {
                    //OferaCRM.FE.Debug.log(self.showLogs, 'Dropdown - bindDropdown', 'No control to bind to.');
                    return;
                }

                //OferaCRM.FE.Debug.log(self.showLogs, 'Dropdown - bindDropdown', 'Binding ' + $dropdown.length + ' controls.');

                $dropdown.each(function () {
                    var $this = $(this);

                    if ($this.attr('data-advanced-dropdown') === 'set') {
                        return;
                    }

                    $this.hide();

                    var placeholder = 'Selectează';
                    if ($this.attr('placeholder') && $this.attr('placeholder').length) {
                        placeholder = $this.attr('placeholder');
                    }

                    var html = '<div class="dropdown-advanced ' + ($this.attr('disabled') ? 'disabled' : '') + '">';
                    html += '<div class="dropdown-head">';
                    html += self._getDropdownHead($this, placeholder);
                    html += '</div>';
                    html += '<div class="dropdown-body">';
                    html += '<span class="top-control close">X</span>';
                    html += self._getFilterInputHtml($this, 5);
                    html += '<ul>';
                    $this.find('option').each(function () {
                        var $option = $(this);

                        // Skip empty values that are bound to placeholder
                        if ((!$option.attr('value') || $option.attr('value') === '0') && $option.html() === placeholder) {
                            return;
                        }

                        html += '<li data-value="' + $option.attr('value') + '" data-name="' + $option.html() + '">';
                        html += $option.html();
                        html += '</li>';
                    });
                    html += '</ul>';
                    html += '</div>';
                    html += '</div>';

                    $this.attr('data-advanced-dropdown', 'set');
                    $this.after(html);

                    // No event binding on disabled
                    if ($this.attr('disabled')) {
                        return;
                    }

                    var $selector = $this;
                    var $container = $this.next();

                    $container.on('click', '.addition-indicator', function (e) {
                        var $this = $(this);
                        e.stopPropagation();
                        switch($this.attr('data-item')) {
                            case 'zona':
                                OferaCRM.FE.Localizare.addArea($("#oferte-locatie_localitate").val());
                                break;
                            case 'strada':
                                OferaCRM.FE.Localizare.addStreet($("#oferte-locatie_localitate").val());
                                break;
                            case 'client':
                            case 'cumparator':
                            case 'proprietar':
                                OferaCRM.FE.Clients.addClient($this.attr('data-item'));
                                break;
                        }
                    });

                    $container.on('click', '.dropdown-head', function (e) {
                        e.stopPropagation();
                        self._clearDropdowns($selector.attr('id'));

                        var $dropdownHead = $(this);
                        if ($dropdownHead.closest('.dropdown-advanced').hasClass('disabled')) {
                            return //OferaCRM.FE.Debug.log(self.showLogs, 'Dropdown - bindDropdown', 'Clicked on dynamically disabled drodpown');
                        }

                        // Clicked on selection x
                        var $removeSelection = $(e.target).closest('.remove-selection');
                        if ($removeSelection.length > 0) {
                            //OferaCRM.FE.Debug.log(self.showLogs, 'Dropdown - bindDropdown', 'Clicked to remove selection.');

                            $selector.find('option').each(function () {
                                var $option = $(this);
                                $option.removeAttr('selected');
                                if (!$option.attr('value') || $option.attr('value') === '0') {
                                    $option.attr('selected', true);
                                }
                            });

                            $container.find('.selection-holder').removeClass('has-value').html(placeholder);
                            $removeSelection.hide();
                            $container.find('span.dropdown-indicator').html('<span class="glyphicon glyphicon-triangle-bottom"></span>');
                            $container.find('.dropdown-body').hide();

                            var $parent = $selector.parent();
                            if ($parent.hasClass('has-success')) {
                                $parent.removeClass('has-success');
                            }
                            if ($parent.hasClass('has-error')) {
                                $parent.removeClass('has-error');
                            }

                            return;
                        }

                        $dropdownHead.toggleClass('open');

                        if ($dropdownHead.hasClass('open')) {
                            $dropdownHead.find('span.dropdown-indicator').html('<span class="glyphicon glyphicon-triangle-top"></span>');
                            $container.find('.dropdown-body').show();
                        } else {
                            $dropdownHead.find('span.dropdown-indicator').html('<span class="glyphicon glyphicon-triangle-bottom"></span>');
                            $container.find('.dropdown-body').hide();
                        }
                    });

                    $container.on('keyup', '.filter-input', function () {
                        var filterValue = $(this).val();

                        if (filterValue) {
                            $container.find('li').each(function () {
                                var $this = $(this);
                                if (OferaCRM.FE.Utils.prepareForMatch($this.html()).indexOf(OferaCRM.FE.Utils.prepareForMatch(filterValue)) >= 0) {
                                    $this.show();
                                } else {
                                    $this.hide();
                                }
                            });
                        } else {
                            $container.find('li').show();
                        }
                    });

                    $container.on('click', '.dropdown-body', function (e) {
                        e.stopPropagation();

                        if ($(e.target).hasClass('close')) {
                            $container.find('.dropdown-head').removeClass('open');
                            $container.find('span.dropdown-indicator').html('<span class="glyphicon glyphicon-triangle-right"></span>');
                            $container.find('.dropdown-body').hide();
                        }
                    });

                    $container.on('click', 'li', function () {
                        var $this = $(this);

                        $this.toggleClass('selected');

                        $selector.find('option').each(function () {
                            var $option = $(this);
                            $option.removeAttr('selected');
                            if ($this.attr('data-value') === $option.attr('value')) {
                                $option.attr('selected', true);
                                $container.find('.selection-holder').addClass('has-value').html($option.html());
                                $container.find('.dropdown-head').removeClass('open').find('.remove-selection').show();
                                $container.find('span.dropdown-indicator').html('<span class="glyphicon glyphicon-triangle-bottom"></span>');
                                $container.find('.dropdown-body').hide();
                            }
                        });

                        $selector.trigger('change');
                    });
                });

                self._bindDropdownClear();
            },

            bindDropdownWithSelect : function () {
                var self = OferaCRM.FE.Dropdown;

                var $dropdownWithSelect = $('.dropdown-with-select');
                if (!$dropdownWithSelect.length) {
                    //OferaCRM.FE.Debug.log(self.showLogs, 'Dropdown - bindDropdownWithSelect', 'No control to bind to');
                    return;
                }

                //OferaCRM.FE.Debug.log(self.showLogs, 'Dropdown - bindDropdownWithSelect', 'Binding ' + $dropdownWithSelect.length + ' controls.');

                $dropdownWithSelect.each(function () {
                    var $this = $(this);

                    if ($this.attr('data-advanced-dropdown') === 'set') {
                        return;
                    }

                    $this.hide();

                    var placeholder = 'Selectează';
                    if ($this.attr('placeholder') && $this.attr('placeholder').length) {
                        placeholder = $this.attr('placeholder');
                    }

                    var html = '<div class="dropdown-advanced has-multiselect">';
                    html += '<div class="dropdown-head">';
                    html += '<span class="selection-holder">';
                    html += placeholder;
                    html += '</span>';
                    html += '<span class="dropdown-indicator"><span class="glyphicon glyphicon-triangle-bottom"></span></span>';
                    html += '</div>';
                    html += '<div class="dropdown-body">';
                    html += '<span class="top-control selector"><i class="far fa-square"></i> Selectează tot</span>';
                    html += '<span class="top-control close">X</span>';
                    html += self._getFilterInputHtml($this, 5);
                    html += '<ul>';
                    $this.find('option').each(function () {
                        var option = this;
                        var $option = $(option);
                        
                        // Skip empty values that are bound to placeholder
                        if ((!$option.attr('value') || $option.attr('value') === '0') && $option.html() === placeholder) {
                            return;
                        }
                        html += self._adaptMultiselectOption(option);
                    });
                    html += '</ul>';
                    html += '</div>';
                    html += '</div>';

                    $this.attr('data-advanced-dropdown', 'set');
                    $this.after(html);

                    var $selector = $this;
                    var $container = $this.next();

                    function updateSelectorValue () {

                        var selectionString = self._getMultiselectHeadString($container);
                        $selector.trigger('change');

                        if (selectionString) {
                            $container.find('.selection-holder').html(selectionString);
                            $container.find('.selection-holder').addClass('with-content');
                        } else {
                            $container.find('.selection-holder').html(placeholder);
                            $container.find('.selection-holder').removeClass('with-content');
                        }
                    }

                    updateSelectorValue();

                    $container.on('click', '.dropdown-head', function (e) {
                        e.stopPropagation();
                        self._clearDropdowns($selector.attr('id'));

                        var $dropdownHead = $(this);
                        if ($dropdownHead.closest('.dropdown-advanced').hasClass('disabled')) {
                            return //OferaCRM.FE.Debug.log(self.showLogs, 'Dropdown - bindDropdownWithSelect', 'Clicked on dynamically disabled multiselect drodpown');
                        }
                        $dropdownHead.toggleClass('open');

                        if ($dropdownHead.hasClass('open')) {
                            $dropdownHead.find('span.dropdown-indicator').html('<span class="glyphicon glyphicon-triangle-top"></span>');
                            $container.find('.dropdown-body').show();
                        } else {
                            $dropdownHead.find('span.dropdown-indicator').html('<span class="glyphicon glyphicon-triangle-bottom"></span>');
                            $container.find('.dropdown-body').hide();
                        }
                    });

                    $container.on('keyup', '.filter-input', function () {
                        var filterValue = $(this).val();

                        if (filterValue) {
                            $container.find('li').each(function () {
                                var $this = $(this);
                                if (OferaCRM.FE.Utils.prepareForMatch($this.html()).indexOf(OferaCRM.FE.Utils.prepareForMatch(filterValue)) >= 0) {
                                    $this.show();
                                } else {
                                    $this.hide();
                                }
                            });
                        } else {
                            $container.find('li').show();
                        }
                    });

                    $container.on('click', '.selector', function () {
                        var $this = $(this);

                        $this.toggleClass('all-selected');

                        if ($this.hasClass('all-selected')) {
                            $this.html('<i class="far fa-check-square"></i> Delesectează tot');
                            $container.find('li i.far').attr('class', 'far fa-check-square');
                            $container.find('li').addClass('selected');
                            $selector.find('option').attr('selected', 'selected');
                            if ($dataBubble.length > 0) {
                                $dataBubble.html(self._getMultiselectDisplayContent($container));
                            }
                        } else {
                            $this.html('<i class="far fa-square"></i> Selectează tot');
                            $container.find('li i.far').attr('class', 'far fa-square');
                            $container.find('li').removeClass('selected');
                            $selector.find('option').removeAttr('selected');
                            if ($dataBubble.length > 0) {
                                $dataBubble.html(self._getMultiselectDisplayContent($container));
                            }
                        }

                        updateSelectorValue();
                    });

                    $container.on('click', '.dropdown-body', function (e) {
                        e.stopPropagation();

                        if ($(e.target).hasClass('close')) {
                            $container.find('.dropdown-head').removeClass('open');
                            $container.find('span.dropdown-indicator').html('<span class="glyphicon glyphicon-triangle-bottom"></span>');
                            $container.find('.dropdown-body').hide();
                        }
                    });

                    var $dataBubble = $("#" + $selector.attr('data-bubble'));

                    $container.on('click', 'li', function () {
                        var $this = $(this);

                        $this.toggleClass('selected');

                        if ($this.hasClass('selected')) {
                            $this.find('i.far').attr('class', 'far fa-check-square');
                            $selector.find('option').each(function () {
                                var $option = $(this);
                                if ($this.attr('data-value') === $option.attr('value')) {
                                    $option.attr('selected', true);
                                }
                            });
                        } else {
                            $this.find('i.far').attr('class', 'far fa-square');
                            $selector.find('option').each(function () {
                                var $option = $(this);
                                if ($this.attr('data-value') === $option.attr('value')) {
                                    $option.removeAttr('selected');
                                }
                            });
                        }

                        updateSelectorValue();

                        if ($dataBubble.length > 0) {
                            $dataBubble.html(self._getMultiselectDisplayContent($container));
                        }
                    });

                    $dataBubble.on('click', 'i.fa-close', function () {
                        //OferaCRM.FE.Debug.log(self.showLogs, 'ImobCRM Dropdown - bindDropdownWithSelect', 'Registered click on closing bubble!');
                        self._clickOnBubbleClose($(this), $container, $selector);
                        updateSelectorValue();
                    });
                });

                self._bindDropdownClear();
            },

            _bindDropdownClear : function () {
                var self = OferaCRM.FE.Dropdown;

                self.vars = self.vars || {};

                if (self.vars.dropdownClearBound) {
                    return;
                }

                $('body').on('click', self._clearDropdowns);

                self.vars.dropdownClearBound = true;
            },

            _clearDropdowns : function (skipId) {
                var $body = $('body');

                if (!skipId) {
                    $body.find('.dropdown-head').removeClass('open');
                    $body.find('.dropdown-body').hide();
                    $body.find('span.dropdown-indicator').html('<span class="glyphicon glyphicon-triangle-bottom"></span>');
                    return;
                }

                $body.find('select').each(function () {
                    var $this = $(this);

                    if ($this.attr('id') === skipId) {
                        return;
                    }

                    $this.next().find('.dropdown-head').removeClass('open');
                    $this.next().find('.dropdown-body').hide();
                    $this.next().find('span.dropdown-indicator').html('<span class="glyphicon glyphicon-triangle-bottom"></span>');
                });
            },

            addAndSelectOption: function ($selector, value, label) {

                var self = OferaCRM.FE.Dropdown;

                //OferaCRM.FE.Debug.log(self.showLogs, 'Dropdown - addAndSelectOption', 'Adding to:', $selector, 'Value: ' + value, 'Label: ' + label);

                // Sanity check
                if (!$selector.length) {
                    //OferaCRM.FE.Debug.err('PC-0030', 'Trying to add option to an inexistent select.');
                    return;
                }

                var valueString = value + '';
                var optionAlreadyPresent = false;

                var $options = $selector.find('option');
                $options.removeAttr('selected');
                $options.each(function () {
                    var $this = $(this);
                    if ($this.attr('value') + '' === valueString) {
                        $this.attr('selected', 'selected');
                        optionAlreadyPresent = true;
                    }
                });

                var $dropdown = $selector.next();
                var $selectionHolder = $dropdown.find('.selection-holder');
                if ($selectionHolder.length) {
                    $selectionHolder.addClass('has-value');
                }
                if ($dropdown.length > 0 && $dropdown.hasClass('dropdown-advanced')) {
                    $dropdown.find('li.selected').removeClass('selected');
                    if (optionAlreadyPresent) {
                        $dropdown.find('li').each(function () {
                            var $this = $(this);
                            if ($this.attr('data-value') + '' === valueString) {
                                $this.addClass('selected');
                                OferaCRM.FE.Utils.updateHtmlContents($selectionHolder, $this.html());
                                return;
                            }
                        });
                        return;
                    }
                }

                // If options is not already present
                $selector.append('<option value="' + valueString + '" selected="selected">' + label + '</option>');
                $dropdown.find('ul').append('<li class="selected" data-value="' + valueString + '" data-name="' + label + '">' + label + '</li>');
                OferaCRM.FE.Utils.updateHtmlContents($selectionHolder, label);
            },

            pushNewList: function ($element, list, valueLabelObject) {
                var self = OferaCRM.FE.Dropdown;

                if (!$element || !$element.length) {
                    return //OferaCRM.FE.Debug.err('PC-0036', 'Trying to bind dropdown list to inexistent dropdown.');
                }

                var $select = $element;
                var $advancedDropdown = $element.next();
                var advancedDropdownPresent = true;

                if (!$advancedDropdown || !$advancedDropdown.length || !$advancedDropdown.hasClass('dropdown-advanced') || !$advancedDropdown.find('ul').length) {
                    advancedDropdownPresent = false;
                    //OferaCRM.FE.Debug.warn('PC-0037', 'Trying to alter an uncompiled advanced dropdown.');
                }

                $select.find('option').remove();
                var $ul;
                if (advancedDropdownPresent) {
                    $advancedDropdown.find('li').remove();
                    $ul = $advancedDropdown.find('ul');
                }

                if (!list || !list.length) {
                    //OferaCRM.FE.Debug.warn('PC-0038', 'No list to be added to dropdown.');
                } else {
                    list.forEach(function (el) {
                        $select.append('<option value="' + el[valueLabelObject.valueKey] + '">' + el[valueLabelObject.labelKey] + '</option>');
                    });
                }

                if (advancedDropdownPresent) {
                    if (list && list.length) {
                        if ($select.hasClass('dropdown-with-select')) {
                            $select.find('option').each(function () {
                                $ul.append(self._adaptMultiselectOption(this));
                            });
                        } else {
                            list.forEach(function (el) {
                                $ul.append('<li data-value="' + el[valueLabelObject.valueKey] + '" data-name="' + el[valueLabelObject.labelKey] + '">' + el[valueLabelObject.labelKey] + '</li>');
                            });
                        }
                    }

                    var placeholder = 'Selectează';
                    if ($select.attr('placeholder') && $select.attr('placeholder').length) {
                        placeholder = $select.attr('placeholder');
                    }

                    if (!$ul.prev().hasClass('option-filter')) {
                        $ul.before(self._getFilterInputHtml($select, 5));
                    }
                    $advancedDropdown.find('.dropdown-head').html(self._getDropdownHead($select, placeholder));
                }
            },
        };
        
        $(document).ready(function () {

            var self = OferaCRM.FE.Dropdown;
            
            // Mobile devices will have their own management for multiple selectors
            if ($(window).width() < 992) {
                return;
            }

            self.bindDropdownWithSelect();
            self.bindDropdown();
        });
    })(jQuery);
})();

(function () {
    // Load check
    if (!jQuery) {
        console.log('NOT LOADED!');
        return;
    }
    
    (function ($) {
        window.OferaCRM = window.OferaCRM || {};
        OferaCRM.FE = OferaCRM.FE || {};
        OferaCRM.FE.InputDropdown = {

            showLogs: true,
            
            bindInputDropdown: function () {
                var $inputDropdown = $(".input-dropdown");
                
                if (!$inputDropdown.length) {
                    return;
                }
                
                $inputDropdown.each(function () {
                    var $this = $(this);
                    
                    if ($this.data("input-dropdown") === "set") {
                        return;
                    }
                    
                    $this.attr("data-input-dropdown", "set");

                    var before = $this.data('before') ? ($this.data('before') + ' ') : '';
                    var after = $this.data('after') ? (' ' + $this.data('after')) : '';
                    
                    var newInputValue = $this.attr('value') ? before + $this.attr('value') + after : '';
                    
                    $this.after("<input type='text' class='form-control c-search__input js-value-display' placeholder='" + 
                            $this.attr('placeholder') + "' value='" + newInputValue + "' />");
                    
                    var $newInput = $this.next();
                    $this.hide();
                    
                    var optionsString = $this.data("options");
                    
                    if (!optionsString) {
                        return;
                    }
                    
                    var options = optionsString.split('|');
                    
                    var html = '<ul class="c-input-dropdown-options">';
                    options.forEach(function (option) {
                        html += '<li class="c-input-dropdown-options__item js-input-option" data-value="' + option + '">' + before + option + after + '</li>';
                    });
                    html += '</ul>';
                    
                    $newInput.after(html);
                    var $container = $newInput.next();
                    
                    $this.parent().on('click', '.js-value-display', function (e) {
                        e.stopPropagation();
                        $newInput.hide();
                        $this.show();
                        $this.focus();
                        $(".c-input-dropdown-options").removeClass("c-input-dropdown-options--active");
                        $container.addClass("c-input-dropdown-options--active");
                    });
                    
                    $this.on('focusout', function () {
                        $this.hide();
                        $newInput.show();
                        
                    });
                    
                    $this.on('keyup', function () {
                        $newInput.attr('value', $this.val() ? before + $this.val() + after : '');
                    });
                    
                    $container.on('click', '.js-input-option', function (e) {
                        e.stopPropagation();
                        var $option = $(this);
                        $this.val($option.data('value'));
                        $newInput.attr('value', $option.html());
                        $container.removeClass("c-input-dropdown-options--active");
                    });
                });
                
                $('body').on('click', function () {
                    $(".c-input-dropdown-options").removeClass("c-input-dropdown-options--active");
                });
            }
        };
        
        $(document).ready(function () {
            var self = OferaCRM.FE.InputDropdown;
            
            self.bindInputDropdown();
        });
    })(jQuery);
})();
(function () {
    // Load check
    if (!jQuery) {
        console.log('NOT LOADED!');
        return;
    }
    
    (function ($) {
        window.OferaCRM = window.OferaCRM || {};
        OferaCRM.FE = OferaCRM.FE || {};
        
        OferaCRM.FE.Search = {
            elements: {
                $formSubmitters: $(".js-form-submit"),
                $mainCategoriesSelector: $(".js-main-category"),
                $subCategoriesSelector: $(".js-sub-category"),
                $leafCategoriesSelector: $(".js-leaf-category"),
                $categoryIdentifier: $(".js-category-id"),
                $searchForm: $(".js-search-form"),
                $resetFilters: $('.js-reset-form'),
                $domForm: $(".js-form"),
                $searchTerm: $(".js-search-term"),
                $locationInput: $(".js-location-field"),
                $locationCountyInput: $(".js-location-county-field"),
                $seoCity: $(".js-city-seo"),
                $seoCounty: $(".js-county-seo")
            },
            
            updateSearchForm: function (categoryId) {
                var self = OferaCRM.FE.Search;
                var E = self.elements;
                
                // Sanity checks
                if (!E.$searchForm.length) {
                    return;
                }
                
                E.$searchForm.children().each(function () {
                    var $this = $(this);
                    if ($this.hasClass('js-price-identifier')) {
                        return;
                    }
                    
                    $this.remove();
                });
                
                E.$categoryIdentifier.val(categoryId);
                E.$searchForm.closest('form').submit();
            }
        };
        
        $.ajax({
            url: window.BASE_URL + '/category/list',
            method: 'GET',
            dataType: 'json'
        }).done(function(data) {
            
            OferaCRM.BackendData = OferaCRM.BackendData || {};
            OferaCRM.BackendData.categories = [];
            
            for (var id in data) {
                if (data[id].parent_id > 0) {
                    if (data[data[id].parent_id].children) {
                        data[data[id].parent_id].children.push(data[id]);
                    } else {
                        data[data[id].parent_id].children = [data[id]];
                    }
                }
            } 
            
            OferaCRM.BackendData.categories = data;
        }).fail(function(error) {
            console.log(error.responseText);
        });
        
        $(document).ready(function () {
            var self = OferaCRM.FE.Search;
            var E = self.elements;
            if (E.$formSubmitters.length > 0) {
                E.$formSubmitters.on('change', function () {
                    var $this = $(this);
                    $this.closest('form').submit();
                });
            }
            
            if (E.$mainCategoriesSelector.length > 0) {
                E.$mainCategoriesSelector.on('change', function () {
                    var $this = $(this);
                    var suffix = E.$seoCounty.val() ? '/judet-' + E.$seoCounty.val() : E.$seoCity.val() ? '/' + E.$seoCity.val() : '';
                    var content = $this.val() ? '/' + $this.val() : '';
                    location.href = window.BASE_URL + content + suffix;
                });
            }

            if (E.$subCategoriesSelector.length > 0) {
                E.$subCategoriesSelector.on('change', function () {
                    var $this = $(this);
                    var suffix = E.$seoCounty.val() ? '/judet-' + E.$seoCounty.val() : E.$seoCity.val() ? '/' + E.$seoCity.val() : '';
                    var content = '/' + E.$mainCategoriesSelector.val();
                    content += $this.val() ? '/' + $this.val() : '';
                    location.href = window.BASE_URL + content + suffix;
                });
            }

            if (E.$leafCategoriesSelector.length > 0) {
                E.$leafCategoriesSelector.on('change', function () {
                    var $this = $(this);
                    var suffix = E.$seoCounty.val() ? '/judet-' + E.$seoCounty.val() : E.$seoCity.val() ? '/' + E.$seoCity.val() : '';
                    var content = '/' + E.$mainCategoriesSelector.val() + '/' + E.$subCategoriesSelector.val();
                    content += $this.val() ? '/' + $this.val() : '';
                    location.href = window.BASE_URL + content + suffix;
                });
            }

            if (E.$resetFilters.length > 0) {
                E.$resetFilters.on('click', function () {
                    location.href = window.BASE_URL + '/cauta';
                });
            }
            
            if (E.$domForm.length > 0) {
                E.$domForm.on('submit', function (e) {
                    e.preventDefault();
                    var $this = $(this);
                    
                    var presentInCanon = [];
                    
                    var canonicalUrl = window.BASE_URL;
                    if (E.$mainCategoriesSelector.val()) {
                        presentInCanon.push('SearchModel[main_cat]');
                        if (E.$mainCategoriesSelector.val() !== '0') {
                           canonicalUrl += '/' + E.$mainCategoriesSelector.val();
                        }
                        
                        // Sanity nesting
                        if (E.$subCategoriesSelector.val()) {
                            presentInCanon.push('SearchModel[sub_cat]');
                            if (E.$subCategoriesSelector.val() !== '0') {
                                canonicalUrl += '/' + E.$subCategoriesSelector.val();
                            }
                            
                            // Sanity nesting
                            if (E.$leafCategoriesSelector.val()) {
                                presentInCanon.push('SearchModel[leaf_cat]');
                                if (E.$leafCategoriesSelector.val() !== '0') {
                                    canonicalUrl += '/' + E.$leafCategoriesSelector.val();
                                }
                            }
                        }
                    }
                    
                    if (E.$seoCounty.val()) {
                        canonicalUrl += '/judet-' + E.$seoCounty.val();
                        presentInCanon.push('SearchModel[location_county]');
                        
                        // Sanity exclusion
                    } else { 
                        if (E.$seoCity.val()) {
                            canonicalUrl += '/' + E.$seoCity.val();
                            presentInCanon.push('SearchModel[location]');
                        }
                    }
                    
                    var term = E.$searchTerm.val();
                    if (term) {
                        canonicalUrl += '/cautare-' + term.replace(/ /g, '-');
                        presentInCanon.push('SearchModel[term]');
                    }
                    
                    var finalUrl = canonicalUrl === window.BASE_URL ? canonicalUrl + '/cauta/' : canonicalUrl;
                    var sep = '?';
                    
                    $("[name^=Search]").each(function () {
                        var $this = $(this);
                        if (presentInCanon.indexOf($this.attr('name')) >= 0) {
                            return;
                        }
                        
                        if (!$this.val()) {
                            return;
                        }
                        
                        if (Array.isArray($this.val()) && !$this.val().length) {
                            return;
                        }

                        if (($this.attr('name') === 'SearchModel[contact_type]' ||
                            $this.attr('name') === 'SearchModel[currency_id]' ||
                            $this.attr('name') === 'SearchModel[order]')
                            && (!$this.prop('checked') ||
                            $this.val() + '' === $this.closest('[role=radiogroup]').data('default') + '')) {
                            return;
                        }
                        
                        finalUrl += sep + $this.attr('name') + '=' + $this.val();
                        sep = '&';
                    });
                    
                    //console.log(finalUrl);
                    location.href = finalUrl;
                    return false;
                });
            }
            
            $("body").on("scroll", function () {
                sessionStorage.scrollPos = $(window).scrollTop();
            });
            
            $("body").scrollTop(sessionStorage.scrollPos || 0);
        });
    })(jQuery);
})();



